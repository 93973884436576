<template>
  <v-footer class="footerStyle">
    <v-row justify="center" no-gutters>
      <router-link to="/" style="text-decoration: none; color: inherit"
        ><v-btn variant="text" class="mx-2" rounded="xl">Home</v-btn>
      </router-link>
      <!--router-link to="/location" style="text-decoration: none; color: inherit"
        ><v-btn variant="text" class="mx-2" rounded="xl"
          >Location</v-btn
        ></router-link
    -->
      <!--router-link
        to="/objectives"
        style="text-decoration: none; color: inherit"
        ><v-btn variant="text" class="mx-2" rounded="xl"
          >Objectives</v-btn
        ></router-link
    -->
      <router-link to="/parcels" style="text-decoration: none; color: inherit"
        ><v-btn variant="text" class="mx-2" rounded="xl"
          >Parcels</v-btn
        ></router-link
      >
      <router-link
        to="/facilities"
        style="text-decoration: none; color: inherit"
        ><v-btn variant="text" class="mx-2" rounded="xl"
          >Organization & Regulations</v-btn
        ></router-link
      >
      <router-link to="/contact" style="text-decoration: none; color: inherit"
        ><v-btn variant="text" class="mx-2" rounded="xl"
          >Contact</v-btn
        ></router-link
      >

      <v-col class="text-center mt-4" cols="12">
        <strong>PIM - Industrial Park - Mosnita Noua</strong>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {};
</script>
<style scoped>
.footerStyle {
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.85);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.85);
  background-color: #353535;
  color: #dddddd;
}
</style>
