<template>
  <v-row no-gutters>
    <v-col v-for="button in buttons" :key="button.text" cols="auto">
      <router-link
        :to="button.route"
        style="text-decoration: none; color: inherit"
      >
        <v-btn>{{ button.text }}</v-btn>
      </router-link>
    </v-col>
    <v-spacer></v-spacer>
    <v-col cols="6"
      ><h2 class="responsiveFont" style="text-align: right">
        Mosnita Noua Industrial Park
      </h2></v-col
    >
  </v-row>
</template>

<script>
export default {
  data() {
    return {
      buttons: [
        { text: "Home", route: "/" },
        //        { text: "Location", route: "/location" },
        //        { text: "Organization", route: "/objectives" },
        { text: "Parcels", route: "/parcels" },
        { text: "Organization & Regulations", route: "/facilities" },
        //        { text: "Contact", route: "/contact" },
      ],
    };
  },
};
</script>

<style scoped>
.v-btn {
  position: relative;
  display: inline-block;
}

.v-btn::before {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
  height: 2px;
  background-color: rgb(0, 174, 255);
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}

.v-btn:hover::before {
  transform: scaleX(1);
  right: 0;
}

.responsiveFont {
  font-size: 22px;
}

/* Change font size for screens smaller than 768px (tablets) */
@media (max-width: 950px) {
  .responsiveFont {
    font-size: 16px;
  }
}

/* Change font size for screens smaller than 576px (smartphones) */
@media (max-width: 650px) {
  .responsiveFont {
    font-size: 12px;
  }
}

@media screen and (max-width: 1850px) {
  .v-btn {
    display: none;
  }
}
</style>
