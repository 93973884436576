<template>
  <v-img :src="require('@/assets/PIM_Contact.png')" cover max-height="878">
    <v-container class="mx-auto customContainer">
      <v-row>
        <v-col cols="12">
          <a
            href="https://www.google.com/maps?q=Parc+Industrial+Mosnita+Noua,+DJ595D,+Mo%C8%99ni%C8%9Ba+Nou%C4%83+307285&ftid=0x47455fafbdb35bcd:0x1aaae5143d3eb664&hl=ro-RO&gl=ro&entry=gps&lucs=47062720&g_ep=CAISBjYuNTQuMRgBIKzfASoINDcwNjI3MjBCAlJP&g_st=com.hammerandchisel.discord.Share"
          >
            <v-img
              :src="require('@/assets/PIM_Location.jpg')"
              cover
              max-width="1200px"
              max-height="600px"
              min-height="200px"
              class="image mx-auto"
              ><div class="image-overlay">See on Google Maps</div>
            </v-img>
          </a>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-container class="responsiveFont">
            <h2 class="center">
              INDUSTRIAL PARK (PIM) - MOSNITA NOUA – TIMIS County
            </h2>
            <h5 class="center textSubtitle">SC GEVAGRO SRL</h5>
          </v-container>
        </v-col>
      </v-row>
      <v-divider length="90%" class="mx-auto"></v-divider>
      <v-row class="responsiveFont">
        <v-col xs="12" md="6" lg="6" order="1" order-md="1">
          <v-container class="mx-auto">
            <h3>Adress</h3>
            <p>Comuna Moșnița Nouă, Calea Medves, 100</p>
            <p>CP 307285, jud Timis</p>
            <p>PLUS CODE: P84C+3C7 Moșnița Nouă</p>
            <p>GPS: (45.7053872, 21.3214598)</p>
          </v-container>
        </v-col>
        <v-col class="centerList" xs="12" md="6" lg="6" order="2" order-md="2">
          <v-container class="mx-auto">
            <h3>Contact</h3>
            <p>Phone: 0256 393 291</p>
            <p>Mobile: 0787.812.657</p>
            <p>Email: gevagro23@gmail.com</p>
            <p>Website: http://pim-parculindustrialmosnita.ro</p>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </v-img>
</template>
<style scoped>
.image {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
}
.image-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: white;
  padding: 0 20px;
  transition: color 0.3s ease-in-out;
  backdrop-filter: blur(5px);
}

.image-overlay:hover {
  color: rgb(65, 217, 255);
}

.v-container:first-child {
  background-color: #fff;
}
.customContainer {
  max-width: 1000px;
  max-height: fit-content;
  margin: 0 auto;
}
.center {
  text-align: center;
}
.textSubtitle {
  color: #999;
}
.responsiveFont {
  font-size: 15px;
}

/* Change font size for screens smaller than 768px (tablets) */
@media (max-width: 767px) {
  .responsiveFont {
    font-size: 15px;
  }
}

/* Change font size for screens smaller than 576px (smartphones) */
@media (max-width: 575px) {
  .responsiveFont {
    font-size: 13px;
  }
}
</style>
